import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Section } from '@/components/elements';

interface ComponentProps {
  title?: string;
  description?: string | ReactNode;
  items: ItemProps[];
  bgColor?: string;
  fullwidth?: boolean;
}

interface ItemProps {
  id: string | number;
  title: string;
  description?: string;
  icon: ReactNode;
  url: string;
  external?: boolean;
}

const Header = styled.div`
  margin-bottom: 2.5rem;
`;

const LinkItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const LinkItemInternal = styled(Link)`
  color: ${(props) => props.theme.palette.primary.text};
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const LinkItemExternal = styled.a`
  color: ${(props) => props.theme.palette.primary.text};
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const LinkItemTitle = styled.h4`
  font-weight: bold;
`;

const LinkItemIcon = styled.div`
  display: flex;
  max-height: 100px;
  margin-bottom: 1rem;
`;

const CtaIconLinks: React.FC<ComponentProps> = ({
  title,
  description,
  items,
  bgColor,
  fullwidth,
}) => (
  <Section bgColor={bgColor} fullwidth={fullwidth}>
    {title || description ? (
      <Header>
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
      </Header>
    ) : (
      ``
    )}

    <LinkItems>
      {items.map((item, index) => {
        if (item.external) {
          return (
            <LinkItemExternal target="_blank" href={item.url} key={index}>
              <LinkItemIcon>{item.icon}</LinkItemIcon>
              <LinkItemTitle>{item.title}</LinkItemTitle>
            </LinkItemExternal>
          );
        }
        return (
          <LinkItemInternal to={item.url} key={index}>
            <LinkItemIcon>{item.icon}</LinkItemIcon>
            <LinkItemTitle>{item.title}</LinkItemTitle>
          </LinkItemInternal>
        );
      })}
    </LinkItems>
  </Section>
);

CtaIconLinks.defaultProps = {
  title: null,
  description: null,
  bgColor: `default`,
  fullwidth: false,
};

export default CtaIconLinks;
